<!-- Start Navbar Area -->
<header id="header">
    <div class="crake-nav marketing-navbar">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-5">
                    <a class="navbar-brand" routerLink="/">
                        <img src="../../../assets/images/SSSIT_logo.jpg" alt="SSSIT_logo"
                            style="height: 80px; width: 747px" class="img-fluid" />
                    </a>
                </div>
                <div class="col-12 col-sm-2 text-center">
                    <h5 class="text_since">SINCE 1999</h5>
                </div>
                <div class="col-12 col-sm-5">
                    <div class="row mt-4">
                        <div class="col-12 col-sm-6 test1">
                            <p class="para-number">
                                <a href="tel:+91 9866144861"><i class="icofont-phone-circle"></i>
                                    9866144861 &nbsp;</a>
                            </p>
                            <p class="para-number">
                                <a href="tel:+91 7032703254"><i class="icofont-phone-circle"></i>
                                    7032703254 &nbsp;</a>
                            </p>
                            <p class="para-number">
                                <a href="tel:+91 7032703253"><i class="icofont-phone-circle"></i>
                                    7032703253</a>
                            </p>
                        </div>
                        <div class="col-1 d-none d-sm-block">
                            <a href="https://www.linkedin.com/in/sssit-kphb-893718325/" class="box" target="_blank">
                                <img src="assets/images/SSS_It_LinkedIn_Logo.webp" alt="SSS_It_LinkedIn_Logo" />
                            </a>
                        </div>
                        <div class="col-1 d-none d-sm-block">
                            <a href="https://www.facebook.com/profile.php?id=100067699524421" class="box"
                                target="_blank">
                                <img src="assets/images/SSS_It_Facebook_Logo.webp" alt="SSS_It_Facebook_Logo" />
                            </a>
                        </div>
                        <div class="col-1 d-none d-sm-block">
                            <a href="https://x.com/RameshVana68976?t=l-A7jupGhdEm5xLY4DL44Q&s=09" class="box"
                                target="_blank">
                                <img src="assets/images/SSS_It_Twitter_Logo.webp" alt="SSS_It_Twitter_Logo" />
                            </a>
                        </div>
                        <div class="col-1 d-none d-sm-block">
                            <a href="https://www.instagram.com/sssitkphb?igsh=MXMxbXp2MXhxNWdhNw==" class="box"
                                target="_blank">
                                <img src="assets/images/SSS_It_Instagram_Logo.webp" alt="SSS_It_Instagram_Logo" />
                            </a>
                        </div>
                        <div class="col-1 d-none d-sm-block">
                            <a href="https://youtube.com/@sssitkphb?si=D2akTYyejPpfZWK5" class="box" target="_blank">
                                <img src="assets/images/SSS_It_youtube-icon.png" alt="SSS_It_Youtube_Logo" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <nav class="navbar navbar-expand-md navbar-light d-none d-sm-block" style="background-color: #e3f2fd">
                <button class="navbar-toggler" type="button" #btntoggle data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav main_nav_items">
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle dot-li" [ngClass]="{
                                    active:
                                        c5.isActive ||
                                        c6.isActive ||
                                        c7.isActive ||
                                        c8.isActive
                                }" data-bs-toggle="dropdown">Our Courses</a>
                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/courses" routerLinkActive="active"
                                        #c5="routerLinkActive">Courses</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLinkActive="active" routerLink="" routerLink="" #c6="routerLinkActive">Full
                                        StackCources</a>
                                    <ul class="dropdown_menu dropdown-menu">
                                        <li class="nav-item">
                                            <a routerLink="/dotnet-fullstack-training" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }" routerLinkActive="active" #c5="routerLinkActive">.Net Full Stack</a>
                                        </li>

                                        <li class="nav-item">
                                            <a routerLink="/java-fullstack-training" routerLinkActive="active"
                                                #c6="routerLinkActive">Java
                                                Full Stack</a>
                                        </li>

                                        <li class="nav-item">
                                            <a routerLink="/python-fullstack-training" routerLinkActive="active"
                                                #c7="routerLinkActive">Python Full Stack</a>
                                        </li>

                                        <li class="nav-item">
                                            <a routerLink="/testing" routerLinkActive="active"
                                                #c8="routerLinkActive">Testing Tools</a>
                                        </li>

                                        <li class="nav-item">
                                            <a routerLink="/webdevelopment-angular" routerLinkActive="active"
                                                #c8="routerLinkActive">Web Development Angular</a>
                                        </li>

                                        <li class="nav-item">
                                            <a routerLink="/digital-marketing" routerLinkActive="active"
                                                #c8="routerLinkActive">Digital Marketing</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" [ngClass]="{
                                    active:
                                        c1.isActive ||
                                        c2.isActive ||
                                        c3.isActive ||
                                        c4.isActive
                                }" data-bs-toggle="dropdown">Our Portfolio</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/it-training" routerLinkActive="active" #c1="routerLinkActive">IT
                                        Training</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/internship" routerLinkActive="active"
                                        #c2="routerLinkActive">Internships</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/workshops" routerLinkActive="active"
                                        #c3="routerLinkActive">Workshops</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/it-development" routerLinkActive="active"
                                        #c4="routerLinkActive">Development</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a routerLink="/placement-preparation" routerLinkActive="active">Placement Preparation
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" [ngClass]="{
                                    active:
                                        c1.isActive ||
                                        c2.isActive ||
                                        c3.isActive ||
                                        c4.isActive
                                }" data-bs-toggle="dropdown">Batches</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/classroom-training" routerLinkActive="active"
                                        #c1="routerLinkActive">Class Room Training</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/online-training" routerLinkActive="active"
                                        #c2="routerLinkActive">Online Training</a>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a routerLink="/gallery" routerLinkActive="active">Gallery</a>
                        </li>

                        <li>
                            <a routerLink="/testimonials" routerLinkActive="active">Testimonials
                            </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="contact-us" routerLinkActive="active">Contact</a>
                        </li>
                    </ul>
                </div>



            </nav>


            <nav class="navbar navbar-expand-md navbar-light d-block d-sm-none" style="background-color: #e3f2fd">
                <button class="navbar-toggler" type="button" #btntoggle data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav main_nav_items">
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle dot-li" [ngClass]="{
                                    active:
                                        c5.isActive ||
                                        c6.isActive ||
                                        c7.isActive ||
                                        c8.isActive
                                }" data-bs-toggle="dropdown">Our Courses</a>
                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/courses" routerLinkActive="active"
                                        #c5="routerLinkActive">Courses</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/dotnet-fullstack-training" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }" routerLinkActive="active" #c5="routerLinkActive">.Net Full Stack</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/java-fullstack-training" routerLinkActive="active"
                                        #c6="routerLinkActive">Java
                                        Full Stack</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/python" routerLinkActive="active" #c7="routerLinkActive">Python Full
                                        Stack</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/testing" routerLinkActive="active" #c8="routerLinkActive">Testing
                                        Tools</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/webdevelopment-angular" routerLinkActive="active"
                                        #c8="routerLinkActive">Web Development Angular</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/digital-marketing" routerLinkActive="active"
                                        #c8="routerLinkActive">Digital Marketing</a>
                                </li>
                            </ul>
                        </li>


                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" [ngClass]="{
                                    active:
                                        c1.isActive ||
                                        c2.isActive ||
                                        c3.isActive ||
                                        c4.isActive
                                }" data-bs-toggle="dropdown">Our Portfolio</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/it-training" routerLinkActive="active" #c1="routerLinkActive">IT
                                        Training</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/internship" routerLinkActive="active"
                                        #c2="routerLinkActive">Internships</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/workshops" routerLinkActive="active"
                                        #c3="routerLinkActive">Workshops</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/it-development" routerLinkActive="active"
                                        #c4="routerLinkActive">Development</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a routerLink="/placement-preparation" routerLinkActive="active">Placement Preparation
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" [ngClass]="{
                                    active:
                                        c1.isActive ||
                                        c2.isActive ||
                                        c3.isActive ||
                                        c4.isActive
                                }" data-bs-toggle="dropdown">Batches</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/classroom-training" routerLinkActive="active"
                                        #c1="routerLinkActive">Class Room Training</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/online-training" routerLinkActive="active"
                                        #c2="routerLinkActive">Online
                                        Training</a>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a routerLink="/gallery" routerLinkActive="active">Gallery</a>
                        </li>
                        <li>
                            <a routerLink="/testimonials" routerLinkActive="active">Testimonials
                            </a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="contact-us" routerLinkActive="active">Contact</a>
                        </li>
                    </ul>
                </div>

            </nav>

        </div>
    </div>
</header>
<!-- End Navbar Area -->

<!-- <app-sidebar></app-sidebar> -->

<!-- Start Search Box -->
<!-- <div id="header-search" class="header-search">
    <button type="button" class="close">×</button>
    <form class="header-search-form">
        <input type="search" value="" placeholder="Type here........" />
        <button type="submit" class="btn btn-primary">Search</button>
    </form>
</div> -->
<!-- End Search Box -->